import { Col, Container, Image, Row } from "react-bootstrap";
import BreadCrumb from "../components/Breadcrumb";
import Won1stPrizein2ndClassInterschoolCompetitionforEnglishRecitation from '../assets/gallery/Won1stPrizein2ndClass-InterschoolCompetitionforEnglishRecitation.png';
import Onlythe2ndGentelmenCadetintheHistoryofIMAtoPerformParaFlying from '../assets/gallery/Onlythe2ndGentelmenCadetintheHistoryofIMAtoPerformParaFlying.png'
import DuringPipingCermony08December2001 from '../assets/gallery/DuringPipingCermony08December2001.png'
import HouseWarmingCermonyAug2006 from '../assets/gallery/gallery1/HouseWarmingCermonyAug2006.png'
import DuringhisUpanayanam1August2006 from '../assets/gallery/DuringhisUpanayanam1August2006.png'
import DuringhisUpanayanam2August2006 from '../assets/gallery/DuringhisUpanayanam2August2006.png'
import Rakhi2007 from '../assets/gallery/gallery1/Rakhi-2007.png'
import JoyfulMoodSistersMarriageSeptember2007 from '../assets/gallery/JoyfulMoodSistersMarriage-September2007.png'
import SistersMarriageSeptember2007 from '../assets/gallery/SistersMarriage-September2007.png'
import WashingBrotherInLawsFeetDuringSistersMarriageSeptember2007 from '../assets/gallery/WashingBrotherInLawsFeetDuringSistersMarriage-September2007.png'
import TakingPrizeafterLeadinghisUnittoVictory from '../assets/gallery/TakingPrizeafterLeadinghisUnittoVictory.png'
import WithDrAPJAbdulKalamashisSpecialOfficer from '../assets/gallery/WithDrAPJAbdulKalamashisSpecialOfficer.png'
import WithhisSeniorOfficersof6Kumaon from '../assets/gallery/WithhisSeniorOfficersof 6Kumaon.png'
import DuringhisEnagement1April2008 from '../assets/gallery/DuringhisEnagement1-April2008.png'
import DuringhisEnagement2April2008 from '../assets/gallery/DuringhisEnagement 2-April2008.png'
import DuringhisEnagement3April2008 from '../assets/gallery/DuringhisEnagement3-April2008.png'
import AppointeeinSchool from '../assets/gallery/AppointeeinSchool.png'
import BestNCCCadetatSchoolin7thClass from '../assets/gallery/BestNCCCadet at Schoolin7thClass.png'
import DuringhisMarriageCelebrations1 from '../assets/gallery/DuringhisMarriageCelebrations1.png'
import DuringhisMarriageCelebrations2 from '../assets/gallery/DuringhisMarriageCelebrations2.png'
import DuringNDADays from '../assets/gallery/DuringNDADays.png'
import EnjoyingaBreak from '../assets/gallery/EnjoyingaBreak.png'
import withMomandBrother from '../assets/gallery/With-his-Mom-and-Brother.png'
import MajorSuresh1 from '../assets/gallery/MajorSureshSuri1.png'
import MajorSuresh2 from '../assets/gallery/MajorSureshSuri2.png'
import MajorSuresh3 from '../assets/gallery/MajorSureshSuri3.png'
import DistributingBreakfast from '../assets/gallery/event/Food_Distribution.mp4'
import DistributingBreakfast2 from '../assets/gallery/event/Food_Distribution_2.png'
import DistributingBreakfast3 from '../assets/gallery/event/Food_Distribution_3.png'
import DistributingBreakfast4 from '../assets/gallery/event/Food_Distribution_4.png'
import DistributingBreakfast5 from '../assets/gallery/event/Food_Distribution_5.png'
import DistributingBreakfast6 from '../assets/gallery/event/Food_Distrubtion_6.png'
import DistributingBreakfast7 from '../assets/gallery/event/Food_Distribution_7.png'
import DistributingBreakfast8 from '../assets/gallery/event/Food_Distribution_8.png'
import DistributingBreakfast9 from '../assets/gallery/event/Food_Distribution-9.png'
import GalleryVideo from '../assets/videos/MajorSureshSuri-ArmyDay2025-2.mp4';
export default function Gallery() {
    return(
        <>
           <BreadCrumb title="Gallery" />
           <section className="gallery-page pt-80 pb-80">
            <Container>
                <Row className="justify-content-center">
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                           <video controls width="100%">
                               <source src={GalleryVideo} alt={GalleryVideo} title={GalleryVideo} />
                           </video>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={Won1stPrizein2ndClassInterschoolCompetitionforEnglishRecitation} alt="Won1stPrizein2ndClassInterschoolCompetitionforEnglishRecitation" title="Won1stPrizein2ndClassInterschoolCompetitionforEnglishRecitation" className="w-100" />
                            <h4 className="gallery-block-title">Won 1st Prize in 2nd Class - Inter school Competition for English Recitation</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={Onlythe2ndGentelmenCadetintheHistoryofIMAtoPerformParaFlying} alt="Only the 2nd Gentlemen Cadet in the History of IMA to Perform Para Flying" title="Only the 2nd Gentlemen Cadet in the History of IMA to Perform Para Flying" className="w-100" />
                            <h4 className="gallery-block-title">Only the 2nd Gentlemen Cadet in the History of IMA to Perform Para Flying</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringPipingCermony08December2001} alt="During Piping Ceremony 08 December 2001" title="During Piping Ceremony 08 December 2001" className="w-100" />
                            <h4 className="gallery-block-title">During Piping Ceremony 08 December 2001</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={HouseWarmingCermonyAug2006} alt="House Warming Ceremony - Aug 2006" title="House Warming Ceremony - Aug 2006" className="w-100" />
                            <h4 className="gallery-block-title">House Warming Ceremony - Aug 2006</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringhisUpanayanam1August2006} alt="During his Upanayanam1 - August 2006" title="During his Upanayanam1 - August 2006" className="w-100" />
                            <h4 className="gallery-block-title">During his Upanayanam1 - August 2006</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringhisUpanayanam2August2006} alt="During his Upanayanam2 - August 2006" title="During his Upanayanam2 - August 2006" className="w-100" />
                            <h4 className="gallery-block-title">During his Upanayanam2 - August 2006</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={Rakhi2007} alt="Rakhi-2007" title="Rakhi-2007" className="w-100" />
                            <h4 className="gallery-block-title">Rakhi-2007</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={JoyfulMoodSistersMarriageSeptember2007} alt="Joyful Mood Sisters Marriage - September 2007" title="Joyful Mood Sisters Marriage - September 2007" className="w-100" />
                            <h4 className="gallery-block-title">Joyful Mood Sisters Marriage - September 2007</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={SistersMarriageSeptember2007} alt="Sisters Marriage - September 2007" title="Sisters Marriage - September 2007" className="w-100" />
                            <h4 className="gallery-block-title">Sisters Marriage - September 2007</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={WashingBrotherInLawsFeetDuringSistersMarriageSeptember2007} alt="Washing Brother In Laws Feet During Sisters Marriage - September 2007" title="Washing Brother In Laws Feet During Sisters Marriage - September 2007" className="w-100" />
                            <h4 className="gallery-block-title">Washing Brother In Laws Feet During Sisters Marriage - September 2007</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={TakingPrizeafterLeadinghisUnittoVictory} alt="Taking Prize after Leading his Unit to Victory" title="Taking Prize after Leading his Unit to Victory" className="w-100" />
                            <h4 className="gallery-block-title">Taking Prize after Leading his Unit to Victory</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={WithDrAPJAbdulKalamashisSpecialOfficer} alt="With Dr APJ Abdul Kalam as his Special Officer" title="With Dr APJ Abdul Kalam as his Special Officer" className="w-100" />
                            <h4 className="gallery-block-title">With Dr APJ Abdul Kalam as his Special Officer</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={WithhisSeniorOfficersof6Kumaon} alt="With his Senior Officers of 6 Kumaon" title="With his Senior Officers of 6 Kumaon" className="w-100" />
                            <h4 className="gallery-block-title">With his Senior Officers of 6 Kumaon</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringhisEnagement3April2008} alt="During his Enagement3 - April 2008" title="During his Enagement3 - April 2008" className="w-100" />
                            <h4 className="gallery-block-title">During his Enagement3 - April 2008</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringhisEnagement2April2008} alt="During his Enagement2 - April 2008" title="During his Enagement2 - April 2008" className="w-100" />
                            <h4 className="gallery-block-title">During his Enagement2 - April 2008</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringhisEnagement1April2008} alt="During his Enagement1 - April 2008" title="During his Enagement1 - April 2008" className="w-100" />
                            <h4 className="gallery-block-title">During his Enagement1 - April 2008</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={AppointeeinSchool} alt="Appointee in School" title="Appointee in School" className="w-100" />
                            <h4 className="gallery-block-title">Appointee in School</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={BestNCCCadetatSchoolin7thClass} alt="Best NCC Cadet at School in 7th Class" title="Best NCC Cadet at School in 7th Class" className="w-100" />
                            <h4 className="gallery-block-title">Best NCC Cadet at School in 7th Class</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringhisMarriageCelebrations1} alt="During his Marriage Celebrations 1" title="During his Marriage Celebrations 1" className="w-100" />
                            <h4 className="gallery-block-title">During his Marriage Celebrations 1</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringhisMarriageCelebrations2} alt="During his Marriage Celebrations 2" title="During his Marriage Celebrations 2" className="w-100" />
                            <h4 className="gallery-block-title">During his Marriage Celebrations 2</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={DuringNDADays} alt="During NDA Days" title="During NDA Days" className="w-100" />
                            <h4 className="gallery-block-title">During NDA Days</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={EnjoyingaBreak} alt="Enjoying a Break" title="Enjoying a Break" className="w-100" />
                            <h4 className="gallery-block-title">Enjoying a Break</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={withMomandBrother} alt="With his Mom and Brother" title="With his Mom and Brother" className="w-100" />
                            <h4 className="gallery-block-title">With his Mom and Brother</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={MajorSuresh1} alt="Major Suresh Suri 1" title="Major Suresh Suri 1" className="w-100" />
                            <h4 className="gallery-block-title">Major Suresh Suri 1</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={MajorSuresh2} alt="Major Suresh Suri 2" title="Major Suresh Suri 2" className="w-100" />
                            <h4 className="gallery-block-title">Major Suresh Suri 2</h4>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="gallery-image-block">
                            <Image src={MajorSuresh3} alt="Major Suresh Suri 3" title="Major Suresh Suri 3" className="w-100" />
                            <h4 className="gallery-block-title">Major Suresh Suri 3</h4>
                        </div>
                    </Col>
                </Row>
                <div>
                    <Row>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <video src={DistributingBreakfast} type="video/mp4" controls poster="Food_Distribution_PosterImage.png" width="100%" height="100%">
                                    Sorry, your browser doesn't support HTML5 video tag.
                                </video>
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <Image src={DistributingBreakfast2} alt="DistributingBreakfast2" title="DistributingBreakfast2" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <Image src={DistributingBreakfast3} alt="DistributingBreakfast3" title="DistributingBreakfast3" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <Image src={DistributingBreakfast4} alt="DistributingBreakfast4" title="DistributingBreakfast4" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <Image src={DistributingBreakfast5} alt="DistributingBreakfast5" title="DistributingBreakfast5" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <Image src={DistributingBreakfast6} alt="DistributingBreakfast6" title="DistributingBreakfast6" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <Image src={DistributingBreakfast7} alt="DistributingBreakfast7" title="DistributingBreakfast7" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <Image src={DistributingBreakfast8} alt="DistributingBreakfast8" title="DistributingBreakfast8" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="gallery-image-block">
                                <Image src={DistributingBreakfast9} alt="DistributingBreakfast9" title="DistributingBreakfast9" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
           </section>
        </>
    )
}